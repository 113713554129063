import React, { useState, useEffect } from 'react';
import './App.css';

function App() {
  const launchDate = new Date('2023-08-01 12:00:00'); // Replace this with your launch date and time
  const currentTime = new Date();

  // Calculate the time remaining until launch
  const timeRemaining = launchDate - currentTime;

  const calculateTimeLeft = () => {
    const timeRemaining = launchDate - new Date();
    const daysRemaining = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
    const hoursRemaining = Math.floor(
      (timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutesRemaining = Math.floor(
      (timeRemaining % (1000 * 60 * 60)) / (1000 * 60)
    );
    const secondsRemaining = Math.floor((timeRemaining % (1000 * 60)) / 1000);

    return {
      days: daysRemaining,
      hours: hoursRemaining,
      minutes: minutesRemaining,
      seconds: secondsRemaining
    };
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div className="App">
      <div className="App-header">
        <h1>Ahsan 2.0, Coming Soon!</h1>
        {/* <div className="countdown">
          <div className="countdown-item">
            <span>{timeLeft.days}</span>
            <span>Days</span>
          </div>
          <div className="countdown-item">
            <span>{timeLeft.hours}</span>
            <span>Hours</span>
          </div>
          <div className="countdown-item">
            <span>{timeLeft.minutes}</span>
            <span>Minutes</span>
          </div>
          <div className="countdown-item">
            <span>{timeLeft.seconds}</span>
            <span>Seconds</span>
          </div>
        </div> */}
        <div className="social-icons">
          {/* Replace the '#' with your social media profile links */}
          <a href="https://twitter.com/theahsanusman" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-twitter"></i>
          </a>
          <a href="https://www.instagram.com/theahsanusman/" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-instagram"></i>
          </a>
          <a href="https://www.linkedin.com/in/theahsanusman/" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-linkedin"></i>
          </a>
        </div>
      </div>
    </div>
  );
}

export default App;
