import React from 'react';
import ReactDOM from 'react-dom';
// import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import * as serviceWorker from './serviceWorker';
import { Route, BrowserRouter } from "react-router-dom"
import { redirect } from "./func"
import App from "./App";
import '@fortawesome/fontawesome-free/css/all.min.css';

ReactDOM.render(
      <App />, document.getElementById('root'));
// const Sl = () => {
//   return(
//     <div>
//       {

//       }
//     </div>
//   )
// }

// ReactDOM.render(
//   <React.StrictMode>
//     <BrowserRouter>
//       <Switch>
//         <Route path="/" exact component={_ => redirect('https://bit.ly/2W9GVhY')} />
//         <Route path="/contact" component={_ => redirect('https://ahsanusmanabduljabbar-1-ahsan-usman.cheetah.builderall.com/home#Contact')} />
//         <Route path="/hire-closers" component={_ => redirect('https://ahsanusmanabduljabbar-1-ahsan-usman.cheetah.builderall.com/hire-closers')} />
//         <Route path="/free-gifts" component={_ => redirect('https://ahsanusmanabduljabbar-1-ahsan-usman.cheetah.builderall.com/free-resources-copy')} />
//         <Route path="/good-to-great-book" component={_ => redirect('https://amzn.to/3cQ1kjc')} />
//         <Route path="/achieve-short-term-goals" component={_ => redirect('https://www.amazon.com/dp/B0865KQHRD')} />
//         <Route path="/program-your-mind-to-learn-frquently" component={_ => redirect('https://www.amazon.com/dp/B086537JG9')} />
//         <Route path="/kill-all-your-limiting-beliefs" component={_ => redirect('https://www.amazon.com/dp/B08658L3JB')} />
//         <Route path="/achieve-all-your-long-term-goals" component={_ => redirect('https://www.amazon.com/dp/B086389B28')} />
//         <Route path="/lead-with-example" component={_ => redirect('https://www.amazon.com/dp/B0862GSPCX')} />
//         <Route path="/free-discovery-call" component={_ => redirect('https://calendly.com/theahsanusman/free-discovery-call')} />
//         <Route path="/10X-Sales" component={_ => redirect('https://ahsanusmanabduljabbar-1-ahsan-usman.cheetah.builderall.com/how-to-10x-your-sales-by-ahsan-usman')} />

//         {/* <Route path="/sl" component={Sl()} /> */}

//       </Switch>
//     </BrowserRouter>
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
